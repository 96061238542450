import React, { ComponentType, ReactElement } from 'react';

type CheckboxProps = {
  id: string;
  label: string | ReactElement;
  className?: string;
  onChange: (event: any) => void;
};

const Checkbox: ComponentType<CheckboxProps> = ({
  id,
  className,
  label,
  onChange,
}: CheckboxProps): ReactElement => (
  <div className={className}>
    <input id={id} onChange={onChange} type="checkbox" />
    <label className="checkbox" htmlFor={id}>{label}</label>
  </div>
);

Checkbox.defaultProps = {
  className: 'checkbox-label-pair',
};

export default Checkbox;
