/* eslint-disable import/prefer-default-export */
import ALIBI from '../../media/logos/ALIBI_logo.svg';
import ANNA from '../../media/logos/ANNA_logo.svg';
import DEKO from '../../media/logos/DEKO_logo.svg';
import ERA from '../../media/logos/ERÄ_logo.svg';
import HYMY from '../../media/logos/HYMY_logo.svg';
import KANAVA from '../../media/logos/KANAVA_logo.svg';
import KIPPARI from '../../media/logos/KIPPARI_logo.svg';
import KOTILAAKARI from '../../media/logos/KOTILÄÄKÄRI_logo.svg';
import KOTILIESI from '../../media/logos/KOTILIESI_logo.svg';
import KOTILIESI_KASITYO from '../../media/logos/KOTILIESI_KÄSITYÖ_logo.svg';
import KOULULAINEN from '../../media/logos/KOULULAINEN_logo.svg';
import MAALLA from '../../media/logos/MAALLA_logo.svg';
import METSASTYS_JA_KALASTUS from '../../media/logos/METSÄSTYS_JA_KALASTUS_logo.svg';
import PARNASSO from '../../media/logos/PARNASSO_logo.svg';
import RAKENNUSMAAILMA from '../../media/logos/RAKENNUSMAAILMA_logo.svg';
import SEURA from '../../media/logos/SEURA_logo.svg';
import SUOMEN_KUVALEHTI from '../../media/logos/SUOMEN_KUVALEHTI_logo.svg';
import SUPERRISTIKOT from '../../media/logos/SUPERRISTIKOT_logo.svg';
import TEKNIIKAN_MAAILMA from '../../media/logos/TEKNIIKAN_MAAILMA_logo.svg';
import VAUHDIN_MAAILMA from '../../media/logos/VAUHDIN_MAAILMA_logo.svg';
import VIVA from '../../media/logos/VIVA_logo.svg';
import VENE from '../../media/logos/VENE_logo.svg';
import OTAVAMEDIA from '../../media/logos/OTAVAMEDIA_logo.svg';

export const logoByPackageId = (packageId?: string): string => {
  if (!packageId) return OTAVAMEDIA;
  const brand = packageId.substring(0, 2);
  switch (brand) {
    case 'AL':
      return ALIBI;
    case 'AN':
      return ANNA;
    case 'DE':
      return DEKO;
    case 'ER':
      return ERA;
    case 'HY':
      return HYMY;
    case 'KA':
      return KANAVA;
    case 'KI':
      return KIPPARI;
    case 'KL':
      return KOTILAAKARI;
    case 'KO':
      return KOTILIESI;
    case 'KU':
      return KOULULAINEN;
    case 'NK':
      return KOTILIESI_KASITYO;
    case 'ML':
      return MAALLA;
    case 'MK':
      return METSASTYS_JA_KALASTUS;
    case 'PA':
      return PARNASSO;
    case 'RM':
      return RAKENNUSMAAILMA;
    case 'SE':
      return SEURA;
    case 'SK':
      return SUOMEN_KUVALEHTI;
    case 'SR':
      return SUPERRISTIKOT;
    case 'TM':
      return TEKNIIKAN_MAAILMA;
    case 'VA':
      return VIVA;
    case 'VE':
      return VENE;
    case 'VM':
      return VAUHDIN_MAAILMA;
    default:
      return OTAVAMEDIA;
  }
};

export const brandByPackageId = (packageId?: string): string => {
  if (!packageId) return 'Otavamedia';
  const brand = packageId.substring(0, 2);
  switch (brand) {
    case 'AL':
      return 'Alibi';
    case 'AN':
      return 'Anna';
    case 'DE':
      return 'Deko';
    case 'ER':
      return 'Erä';
    case 'HY':
      return 'Hymy';
    case 'KA':
      return 'Kanava';
    case 'KI':
      return 'Kippari';
    case 'KL':
      return 'Kotilääkäri';
    case 'KO':
      return 'Kotiliesi';
    case 'KU':
      return 'Koululainen';
    case 'NK':
      return 'Kotiliesi Käsityö';
    case 'ML':
      return 'Maalla';
    case 'MK':
      return 'Metsästys ja Kalastus';
    case 'PA':
      return 'Parnasso';
    case 'RM':
      return 'Rakennusmaailma';
    case 'SE':
      return 'Seura';
    case 'SK':
      return 'Suomen Kuvalehti';
    case 'SR':
      return 'Superristikot';
    case 'TM':
      return 'Tekniikan Maailma';
    case 'VA':
      return 'Viva';
    case 'VE':
      return 'Vene';
    case 'VM':
      return 'Vauhdin Maailma';
    default:
      return 'Otavamedia';
  }
};
