/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/naming-convention */
import {
  ComponentType,
} from 'react';
import * as Sentry from '@sentry/react';
import AuthProvider from '../common/providers/Auth';
import OrderProvider from '../common/providers/Order';
import OrderHandlingProvider from '../common/providers/OrderHandling';
import ToasterProvider from '../common/providers/Toaster';
import DisplayProvider from '../common/providers/Display';
import Router from './Router';
import PageProvider from '../common/providers/Page';
import { SENTRY_ENDPOINT, SENTRY_DSN, SENTRY_ENABLED } from '../common/constants';

if (SENTRY_ENABLED === '1') {
  Sentry.init({
    dsn: SENTRY_DSN,
    tunnel: SENTRY_ENDPOINT,
    tracesSampleRate: 1.0,
    environment: process.env.NODE_ENV,
  });
}

// Todo: Cleanup context stacking
const App: ComponentType = () => (
  <PageProvider>
    <Sentry.ErrorBoundary>
      <AuthProvider>
        <OrderHandlingProvider>
          <OrderProvider>
            <ToasterProvider>
              <DisplayProvider>
                <Router />
              </DisplayProvider>
            </ToasterProvider>
          </OrderProvider>
        </OrderHandlingProvider>
      </AuthProvider>
    </Sentry.ErrorBoundary>
  </PageProvider>
);
export default App;
