import { ComponentType, ReactElement } from 'react';
import { brandByPackageId, logoByPackageId } from '../common/utils/logoUtils';

type BannerLogoProps = {
  packageId: string | undefined
};

const BannerLogo: ComponentType<BannerLogoProps> = ({
  packageId,
}: BannerLogoProps): ReactElement => (
  <img alt={`${brandByPackageId(packageId)}-logo`} className="banner-img" src={logoByPackageId(packageId)} />
);

export default BannerLogo;
