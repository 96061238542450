/* eslint-disable no-underscore-dangle */
/* eslint-disable react/no-danger */
import React, {
  ComponentType, ReactElement, useContext,
} from 'react';
import { DisplayContext } from '../../common/context';
import { DisplayMode } from '../../common/enums';
import t from '../../locales/fi';
import { Offer } from '../../common/models/Offer';
import PopupWindow from './PopupWindow';

type OrderInfoProps = {
  offer: Offer;
};

const OrderInfo: ComponentType<OrderInfoProps> = ({
  offer,
}: OrderInfoProps): ReactElement => {
  const { displayMode } = useContext(DisplayContext);

  const {
    packageImage,
    normalPrice,
    offerPrice,
    orderDurationType,
    duration,
    name,
    salesType,
    extraText2,
    packageDescription,
    packageExtraDescription,
  } = offer!.magazines![0];

  const extraText = () => {
    if (extraText2?.__html) {
      const isParagraph = extraText2.__html.toLowerCase().includes('<p>');
      return (
        <span
          className={`extraText ${isParagraph ? 'paragraph' : ''}`}
          dangerouslySetInnerHTML={{ __html: extraText2.__html }}
        />
      );
    }
    return null;
  };

  const popupWindow = () => {
    if (packageDescription?.__html) {
      return (
        <PopupWindow
          name={name}
          image={packageImage}
          text={packageDescription}
          extraText={packageExtraDescription}
          displayMode={displayMode}
        />
      );
    }
    return null;
  };

  const getImage = () => (
    <img className="package-img" alt="" src={packageImage} />
  );

  return (
    <div className="order-info-container" style={{ background: offer.themeColor }}>
      <h1 className="component-title">{t.YOU_ARE_ORDERING}</h1>
      <div className="order-info-wrapper">
        {displayMode === DisplayMode.DESKTOP && getImage()}
        <div className="offer-wrapper">
          <span className="bolder">{name}</span>
          <span className="bolder">{`${offerPrice} € / ${duration} ${orderDurationType}`}</span>
          <span>{`(${t.NORMAL_SHORT} ${normalPrice} € / ${duration} ${orderDurationType})`}</span>
          <span className="mt-1 mb-1">{salesType}</span>
          {popupWindow()}
        </div>
      </div>
      {extraText()}
    </div>
  );
};

export default OrderInfo;
