/* eslint-disable consistent-return */
import { useContext, useMemo } from 'react';
import {
  PageContext,
} from '../common/context';
import { InvisiblePhase, Phase, ProgressPhase } from '../common/enums';
import t from '../locales/fi';
import BannerLogo from './BannerLogo';
import LeftArrow from '../media/arrow-left.svg';
import { addQueryParametersToUrl } from '../common/utils/commonUtils';

type HeaderProps = {
  packageId?: string;
};

const defaultHeaderProps = {
  packageId: undefined,
};

const buildLogoutUrl = (articleURL?: string) => {
  const baseUrl = `${window.location.origin}/logout`;
  if (articleURL) {
    return addQueryParametersToUrl({ url: baseUrl, params: { redirectTo: articleURL } });
  }
  return baseUrl;
};

const shouldDisplayBackButton = (phase: InvisiblePhase | ProgressPhase) => phase !== Phase.SUMMARY;

const Header = ({ packageId }: HeaderProps) => {
  const { phase, session } = useContext(PageContext);
  const backButton = useMemo(() => {
    const goBackAction = (): any => {
      // eslint-disable-next-line default-case
      switch (phase) {
        case Phase.CONTACT_AND_PAYMENT:
        case Phase.LOGIN:
          window.location.assign(buildLogoutUrl(session?.params?.articleURL));
      }
    };

    return (
      <button onClick={goBackAction} className="go-back-button" type="submit">
        <img src={LeftArrow} alt="" />
        {t.GO_BACK}
      </button>
    );
  }, [phase]);

  return (
    <header>
      <BannerLogo packageId={packageId} />
      {shouldDisplayBackButton(phase) && backButton}
    </header>
  );
};

Header.defaultProps = defaultHeaderProps;

export default Header;
