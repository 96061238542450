/* eslint-disable react/require-default-props */
import React, { ComponentType, ReactElement } from 'react';
import t from '../../locales/fi';
import RadioButton from './PaymentRadioButton';
import MastercardLogo from '../../media/mastercard.svg';
import VisaLogo from '../../media/visa.svg';
import VisaFullColor from '../../media/visa-full-color.jpg';
import {
  PaymentMethod,
} from '../../common/enums';
import { Offer } from '../../common/models/Offer';

type PaymentMethodProps = {
  setPaymentMethod: (paymentMethod: PaymentMethod) => void;
  paymentMethod: string;
  offer: Offer;
};

const OfferPaymentMethod: ComponentType<PaymentMethodProps> = ({
  setPaymentMethod,
  paymentMethod,
  offer,
}: PaymentMethodProps): ReactElement => {
  const {
    cardPaymentTitle, enabledPaymentMethods,
  } = offer!.payment;

  const getCreditCardLabel = () => (
    <div className="label-container">
      <span>{t.CARD_PAYMENT}</span>
    </div>
  );

  const getInvoiceLabel = () => (
    <div className="label-container">
      <div>{t.INVOICE}</div>
    </div>
  );

  const getMobilepayLabel = () => (
    <div className="label-container">
      <div>{t.MOBILEPAY}</div>
    </div>
  );

  return (
    <div className="offer-payment-method-container">
      <fieldset className="payment-method-fieldset">
        <legend className="component-title">{cardPaymentTitle}</legend>
        <div className="payment-radio-group">
          {enabledPaymentMethods.includes('CREDIT_CARD') && (
            <RadioButton
              name="paymentMethod"
              checked={paymentMethod === PaymentMethod.CARD}
              id="card"
              label={getCreditCardLabel()}
              onChange={() => setPaymentMethod(PaymentMethod.CARD)}
            />
          )}
          {enabledPaymentMethods.includes('INVOICE') && (
          <RadioButton
            name="paymentMethod"
            checked={paymentMethod === PaymentMethod.INVOICE}
            id="invoice"
            label={getInvoiceLabel()}
            onChange={() => setPaymentMethod(PaymentMethod.INVOICE)}
          />
          )}
          {enabledPaymentMethods.includes('CREDIT_CARD') && (
            // Mobilepay is allowed whenever card is allowed
            <RadioButton
              name="paymentMethod"
              checked={paymentMethod === PaymentMethod.MOBILEPAY}
              id="mobilepay"
              label={getMobilepayLabel()}
              onChange={() => setPaymentMethod(PaymentMethod.MOBILEPAY)}
            />
          )}
        </div>
      </fieldset>
      {paymentMethod === PaymentMethod.CARD && (
        <div className="credit-card-images">
          <img alt="visa" className="payment-visa-img" src={VisaLogo} />
          <img alt="visa" className="payment-visa-img" src={VisaFullColor} />
          <img alt="mastercard" className="payment-mastercard-img" src={MastercardLogo} />
        </div>

      )}
    </div>
  );
};

export default OfferPaymentMethod;
