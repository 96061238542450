/* eslint-disable max-len */
import {
  ReactElement,
  ComponentType,
  useEffect,
  useState,
  useContext,
} from 'react';
import { v4 as uuidv4 } from 'uuid';
import NewOrderDevComponent from './NewOrder/Main';
import TestSummaryPage from './Summary/TestSummaryPage';
import { Magazine } from '../../common/models/Offer';
import { api } from '../../common/api';
import OfferDropDown from './OfferDropDown';
import { ToasterContext } from '../../common/context';
import Spinner from '../../components/Spinner';

type DevelopmentHelperProps = {};

export enum Choice {
  NEW_ORDER = 'NEW_ORDER',
  NONE = 'NONE',
  SUMMARY_PAGE = 'SUMMARY_PAGE',
}

const chosenMagazineDiv = ({
  duration,
  name,
  packageId,
  orderDurationType,
  offeringId,
  id: offerId,
  offerPrice,
}: Magazine) => (
  <div>
    {`${name} (${packageId}/${duration}${orderDurationType}/${offerPrice}€) -
      offeringId: ${offeringId} offerId: ${offerId}`}
  </div>
);

const DevelopmentHelper: ComponentType<
DevelopmentHelperProps
> = (): ReactElement | null => {
  const [choice, setChoice] = useState<Choice>(Choice.NONE);
  const [magazineOptions, setMagazineOptions] = useState(undefined as any);
  const [chosenMagazine, setChosenMagazine] = useState(undefined as any);
  const [additionalContent, setAdditionalContent] = useState(null);
  const { showToaster } = useContext(ToasterContext);
  const sid = uuidv4();

  // Testing parameters ->
  useEffect((): void => {
    const pathName = String(window.location.pathname);
    const testMagazine = pathName?.replace('/dev/', '');
    if (testMagazine && ['TM', 'MK', 'KO', 'AN', 'VM', 'SK', 'VE'].includes(testMagazine)) {
      // eslint-disable-next-line default-case
      switch (testMagazine) {
        case 'TM':
          window.location.href = `/?offeringId=400120&offerId=200005&sid=${sid}`;
          break;
        case 'SK':
          window.location.href = `/?offeringId=400135&offerId=200041&sid=${sid}`;
          break;
        case 'MK':
          window.location.href = `/?offeringId=400030&offerId=200073&sid=${sid}`;
          break;
        case 'KO':
          window.location.href = `/?offeringId=400068&offerId=200070&sid=${sid}`;
          break;
        case 'AN':
          window.location.href = `/?offeringId=400127&offerId=200111&sid=${sid}`;
          break;
        case 'VM':
          window.location.href = `/?offeringId=400127&offerId=200025&sid=${sid}`;
          break;
        case 'VE':
          window.location.href = `/?offeringId=400172&offerId=200051&sid=${sid}`;
          break;
      }
    }
  }, [window.location.pathname]);
  // <- Testing parameters

  const handleOfferDropDownChange = (event: any) => {
    const selectionId = event.target.value;
    const magazine = magazineOptions[selectionId];
    setChosenMagazine(magazine);
  };

  const getAndSetMagazineOptions = async () => {
    const result = await api('offer/all', { method: 'GET' });
    const { status, data }: { status: number; data: undefined | Magazine[] } = result;
    if (status === 200) {
      const magazines: Magazine[] = data!;
      setMagazineOptions(magazines);
      setChosenMagazine(magazines[0]);
      return;
    }
    showToaster('Error fetching offers!', data);
  };

  useEffect(() => {
    if (!magazineOptions) {
      getAndSetMagazineOptions();
    }
  }, []);

  const choices = () => (
    <div>
      <button id="test-new-order" type="button" onClick={() => setChoice(Choice.NEW_ORDER)}>Test new order</button>
      <br />
      <br />
      <button id="test-summary-page" type="button" onClick={() => setChoice(Choice.SUMMARY_PAGE)}>Test summary page</button>
    </div>
  );

  const contentByChoice = () => {
    switch (choice) {
      case Choice.NEW_ORDER:
        return <NewOrderDevComponent chosenMagazine={chosenMagazine} />;
      case Choice.SUMMARY_PAGE:
        return <TestSummaryPage magazine={chosenMagazine} setAdditionalContent={setAdditionalContent} />;
      default:
        return choices();
    }
  };

  if (!magazineOptions) return <Spinner />;

  const offerDiv = () => (
    <div>
      <div><b>Offer:</b></div>
      <OfferDropDown offers={magazineOptions} onChange={handleOfferDropDownChange} />
      {chosenMagazine && chosenMagazineDiv(chosenMagazine)}
    </div>
  );

  return (
    <div>
      <div className="Page">
        <main className="page-container">
          <div className="content-wrapper">
            {choice === Choice.NEW_ORDER && offerDiv()}
            {
              contentByChoice()
            }
          </div>
          {choice === Choice.SUMMARY_PAGE && offerDiv()}
          {additionalContent && additionalContent}
        </main>
      </div>
    </div>
  );
};

export default DevelopmentHelper;
