/* eslint-disable react/no-danger */
import React, { ComponentType, ReactElement, useContext } from 'react';
import { DisplayContext } from '../common/context';
import { DisplayMode, PaymentMethod } from '../common/enums';
import t from '../locales/fi';
import { capitalizeFirstLetter, getFinnishDateStringFromDate } from '../common/utils/commonUtils';
import { Offer, OrderType } from '../common/models/Offer';

type OrderSummaryProps = {
  paymentMethod: PaymentMethod;
  offer: Offer;
  subscription: any;
};

const OrderSummary: ComponentType<OrderSummaryProps> = ({
  paymentMethod, offer, subscription,
}: OrderSummaryProps): ReactElement => {
  const {
    packageImage,
    normalPrice,
    offerPrice,
    orderType,
    orderDurationType,
    duration,
    name,
    salesType,
  } = offer.magazines![0];

  const { displayMode } = useContext(DisplayContext);

  const {
    creditcardInfoTextContinuousSub,
    invoicingInfoText,
    mobilePayInfoTextContinuousSub,
  } = offer.options!.paywall;

  const { deliveryStartDate } = subscription;

  const getSubscriptionText = () => {
    if (paymentMethod === PaymentMethod.CARD) {
      return (
        <span dangerouslySetInnerHTML={{ __html: creditcardInfoTextContinuousSub }} />
      );
    }
    if (paymentMethod === PaymentMethod.MOBILEPAY) {
      return (
        <span dangerouslySetInnerHTML={{ __html: mobilePayInfoTextContinuousSub }} />
      );
    }
    return (
      <span dangerouslySetInnerHTML={{ __html: invoicingInfoText }} />
    );
  };

  return (
    <div className="order-info-container" style={{ background: offer.themeColor }}>
      <h2 className="summary-info-text component-title">
        {t.ORDER_SUMMARY}
      </h2>
      <div className="order-summary-wrapper">
        {displayMode === DisplayMode.DESKTOP && <img className="summary-img" alt="" src={packageImage} />}
        <div className="order-summary">
          <div className="mb-1">
            {t.OFFER_PERIOD}
            :
          </div>
          <span className="bolder">{name}</span>
          <span className="bolder">{`${offerPrice}€ / ${duration} ${orderDurationType}`}</span>
          <span>{`(${t.NORMAL_SHORT} ${normalPrice}€ / ${duration} ${orderDurationType})`}</span>
          <span className="mt-1">{salesType}</span>
          <span>{`${t.PAYMENT_METHOD}: ${capitalizeFirstLetter(paymentMethod)}`}</span>
          <span className="mt-1">
            {t.ORDER_STARTS}
            :
            {' '}
            {getFinnishDateStringFromDate(new Date(deliveryStartDate))}
          </span>
          <span className="subscription-info-text">
            {orderType === OrderType.CONTINUOUS && getSubscriptionText()}
          </span>
        </div>
      </div>
    </div>
  );
};

export default OrderSummary;
