export type Offer = {
  id: string;
  offeringId: string;
  magazines?: Magazine[];
  active: string;
  banners: Banner;
  dontShowUpperLogo1: string;
  dontShowUpperLogo2: string;
  requiresTyrkyte: string;
  disposableTyrkyte: string;
  brands: object[];
  brandsOnOffering: string[];
  gifts: object[];
  payment: PaymentOptions;
  terms: ObjectWithHtmlProperty;
  options: OfferOptions;
  signed: string;
  errored: boolean;
  themeColor: string;
  targetGroupId: number;
};

// This is mapped to salestype in API2
export enum OrderType {
  CONTINUOUS = '01', // Jatkuva tilaus
  FIXED = '02', // Määräaikainen
  SINGLE_ISSUE = '03', // Irtonumero
  CHECK_ISSUE = '04', // Tarkistuskappale
  FREE_ISSUE = '05', // Vapaakappale
  SAMPLE = '06', // Näyte
  UNKNOWN = '99', // Tuntematon
}

export type Magazine = {
  campaignNumber: string;
  numOfPayments: number;
  offerExtraImage: string;
  id: string;
  offerPrice: string;
  duration: string;
  orderDurationType: string;
  packageId: string;
  name: string;
  orderStartingDate: string;
  orderEndDate: string;
  maxNumOfPayments: string;
  normalPrice: string;
  orderType: string;
  packageImage: string;
  packageDescription: ObjectWithHtmlProperty;
  packageExtraDescription: ObjectWithHtmlProperty;
  askCustomerNumber: string;
  dontAllowOrderingForFriend: string;
  orderSuccessText: string;
  salesType: string;
  orderStarts: string;
  orderEnds: string;
  offeringId: string;
  kayakOrderType: string;
  kayakOrderTypeDetail: string;
  starts: string;
  ends: string;
  startBeginning: string;
  startEnding: string;
  lastDateToSubmit: string;
  requiresTyrkyte: string;
  requiresPoll: string;
  payerCustomerId: string;
  discountPercentage: number;
  salespersonId: string;
  targetGroupId: number;
  description: string;
  paymentMethodInvoice: string;
  paymentMethodCreditCard: string;
  orderingTerms: ObjectWithHtmlProperty;
  showOrderMoreLink: ObjectWithHtmlProperty;
  showMoreLink: string;
  showMoreLinkText: string;
  moreLinksUrl: string;
  sellMoreText: string;
  extraText1: ObjectWithHtmlProperty;
  extraText2: ObjectWithHtmlProperty;
  extraText3: ObjectWithHtmlProperty;
  hideExtraText1: string;
  extraTextInterestSurvey1: ObjectWithHtmlProperty;
  extraTextInterestSurvey2: ObjectWithHtmlProperty;
  couponCodeInfoText: ObjectWithHtmlProperty;
  dontShowGifts1: string;
  dontShowGifts2: string;
  dontShowNormalPrice: string;
  removeCustomerOrders: string;
  showTopX: string;
  orderByScore: string;
  orderHereText: string;
  disposableCode: string;
  campaignChannel: string;
  siteTitle: string;
  selectGiftText: string;
  optionalGifts: string;
  companyCustomerNumber: string;
  productLines: Array<ProductLine>;
  siteOptions: SiteOptions;
};

type OfferOptions = {
  creditcardInfoText: string;
  creditcardInfoDurationText: string;
  paywall: PaywallOptions;
  siteTitle: string;
};

type PaywallOptions = {
  creditcardInfoText: string;
  creditcardInfoTextContinuousSub: string;
  emailInfoText: string;
  invoicingInfoText: string;
  jatkuvaSubscriptionInfoText: string;
  jatkuvaSubscriptionTerms: string;
  maSubscriptionInfoText: string;
  maSubscriptionTerms: string;
  subscriptionSuccesfulText: string;
  subscriptionSuccesfulOmAccountText: string;
  subscriptionSuccesfulErrorListText: string;
  mobilePayInfoText: string;
  mobilePayInfoTextContinuousSub: string;
};

type ProductLine = {
  productname: string;
  paperCode: string;
  productCode: string;
  price: string;
};

type SiteOptions = {
  renewalPrice: object[];
  renewalPriceString: string;
};

type PaymentOptions = {
  cardPaymentTitle: string;
  enabledPaymentMethods: string[];
  installmentsMessage: string;
};

export type ObjectWithHtmlProperty = {
  __html: 'string';
};

type Banner = {
  packageBanner: string;
  bannerText: ObjectWithHtmlProperty;
  bannerText2: ObjectWithHtmlProperty;
  topBannerImage: string;
  topBannerImage2: string;
  topBannerImageInterestSurvey: string;
};
