import {
  ComponentType, ReactElement, useContext, useEffect,
} from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';
import { useApi } from '../common/api';
import { BACKEND_ENDPOINT } from '../common/constants';
import { PageContext } from '../common/context';
import { Offer } from '../common/models/Offer';
import { initSession } from '../common/models/Session';
import Session from '../common/session';
import { filterEmptyParams } from '../common/utils/commonUtils';
import { consentGiven } from '../common/form/utils';

const InitApp: ComponentType<any> = (): ReactElement | null => {
  const offerApi = useApi(
    undefined,
    {},
    {
      headers: { 'x-api-key': 'REPLACEME' as string },
      endpoint: BACKEND_ENDPOINT as string,
    },
  );
  const referrerUrl = document.referrer;
  const {
    offer, setOffer, setError, setSession,
  } = useContext(PageContext);

  // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-unused-vars
  const [params, _setParams] = useSearchParams();

  const initializeApp = ({
    offeringId, offerId, articleURL, sid,
  }: {
    offeringId: string,
    offerId: string,
    articleURL: string | null,
    sid: string | null,
  }): void => {
    Session.clearStore({ useSessionStorage: false });
    setOffer(null);
    offerApi.get('offer', {
      queryParams: filterEmptyParams({
        offerId,
        offeringId,
        articleURL,
        sid,
      }),
    });
  };

  useEffect((): void => {
    const [offeringId, offerId, articleURL, consents, sid] = [
      params.get('offeringId'),
      params.get('offerId'),
      params.get('articleURL'),
      params.get('consents'),
      params.get('sid'),
    ];
    if (!!offeringId && !!offerId) {
      initializeApp({
        offeringId, offerId, articleURL, sid,
      });
      setSession({
        ...initSession({
          referrerSessionId: sid || undefined,
          referrerUrl,
          consentGiven: consents ? consentGiven(consents) : false,
        }),
        params: {
          offeringId,
          offerId,
          articleURL,
          consents,
          sid,
        },
      });
      return;
    }
    setError({ errorMessage: undefined });
  }, []);

  useEffect((): void => {
    if (!offerApi.loading) {
      const offerError = offerApi.error?.message || (offerApi.data as any)?.error;
      if (offerError) {
        setError({ errorMessage: undefined });
      }
      if (offerApi.data) {
        setOffer(offerApi.data as Offer);
      }
    }
  }, [offerApi.loading]);

  if (offer) return <Navigate to="/" />;

  return null;
};

export default InitApp;
